class OrgUtils {
  /**
   * @description Format the order secret to a readable format
   * @returns {String} order's secret
   * @throws {Error}
  */
  static formatSecret(secret, isOrganizationSecret) {
    if(!secret) return "";
    if(isOrganizationSecret) {
        //org has 10 characters, so we need to format it to 5-5
        return secret.match(/.{1,5}/g).join('-');
    }
    //os has 14 characters, so we need to format it to 7-7
    return secret.match(/.{1,7}/g).join('-');
  }
}

export default OrgUtils;